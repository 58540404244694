<h1>Recuperar contraseña</h1>
<p>Por favor, ingrese su Número de Documento registrado en el sistema para poder obtener una nueva contraseña.</p>
<form [formGroup]="loginForm" class="authForm">
    <mat-form-field appearance="outline" class="formFieldLogin" color="primary">
        <mat-label>DNI</mat-label>
        <input formControlName="dni" type="text" matInput placeholder="DNI" autocomplete="dni" required
            maxlength="11" minlength="6" (keypress)="validateService.validateFormat($event)" (focus)="loginFormFocus('dni')">
        <mat-error *ngIf="loginForm.controls['dni'].errors?.['required']">
            * Debe ingresar un DNI válido.
        </mat-error>
        <mat-error *ngIf="loginForm.controls['dni'].errors?.['minlength']">
            * Debe ingresar un DNI de al menos 7 dígitos.
        </mat-error>
    </mat-form-field>
    <div class="recaptchaContainer" *ngIf="recaptchaEnabled === true">
        <re-captcha id="captcha" name="captcha" formControlName="captcha" required (resolved)="resolved($event)"
            (error)="onError($event)" errorMode="handled"></re-captcha>
    </div>
    <div class="button-action">
        <button style="width: 100%" mat-raised-button class="btnLogin" #btn type="submit" color="primary"
            (click)='register()' [disabled]="loginForm.invalid">Restablecer
        </button>
        <a mat-raised-button class="btnLogin" #btn type="button" style="width: 100%"
            routerLink="/auth/iniciar-sesion">
            CANCELAR
        </a>
    </div>
</form>
