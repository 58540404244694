<h1 class="title1">Alta de usuario</h1>
<p>Complete todos los campos para crear una nueva cuenta</p>

    <form [formGroup]="registerForm">
        <mat-form-field appearance="outline" class="formFieldLogin">
            <mat-label>DNI</mat-label>
            <input formControlName="dni" type="text" matInput placeholder="DNI" autocomplete="dni" required
                maxlength="11" minlength="7" (keypress)="validateService.validateFormat($event)"
                (focus)="registerFormFocus('dni')">
            <mat-error *ngIf="registerForm.controls['dni'].errors?.['required']">
                * Debe ingresar un DNI válido.
            </mat-error>
            <mat-error *ngIf="registerForm.controls['dni'].errors?.['minlength']">
                * Debe ingresar un DNI de al menos 7 dígitos.
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="formFieldLogin">
            <mat-label>Género Según DNI</mat-label>
            <select formControlName="sexo" matNativeControl required>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
                <option value="X">No Binario</option>
            </select>
            <mat-error *ngIf="registerForm.controls['sexo'].errors?.['required']">
                * Campo requerido
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="formFieldLogin">
            <mat-label>Número de trámite</mat-label>
            <input formControlName="nroTramite" type="text" matInput placeholder="Numero de tramite"
                autocomplete="dni" required minlength="5"
                (keypress)="validateService.validateFormat($event)" (focus)="registerFormFocus('dni')">
            <mat-error *ngIf="registerForm.controls['nroTramite'].errors?.['required']">
                * Campo requerido
            </mat-error>
        </mat-form-field>
        <div class="button-action">
            <a mat-raised-button class="btnLogin" #btn type="button" style="width: 100%"
                routerLink="/auth/iniciar-sesion">
                CANCELAR
            </a>
            <button style="width: 100%" mat-raised-button class="btnLogin" #btn type="submit" color="primary"
                (click)='register()' [disabled]="registerForm.invalid">SIGUIENTE
            </button>
        </div>
    </form>
