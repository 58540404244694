<ng-container *ngIf="loginForm">
    <form [formGroup]="loginForm" class="authForm">
        <mat-form-field appearance="outline" class="formFieldLogin" color="primary">
            <mat-label>DNI</mat-label>
            <input formControlName="dni" type="text" matInput placeholder="DNI" autocomplete="dni" required
                maxlength="11" minlength="7" (keypress)="validateService.validateFormat($event)" (focus)="loginFormFocus('dni')">
            <mat-error *ngIf="loginForm.controls['dni'].errors?.['required']">
                * Debe ingresar un DNI válido.
            </mat-error>
            <mat-error *ngIf="loginForm.controls['dni'].errors?.['minlength']">
                * Debe ingresar un DNI de al menos 7 dígitos.
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="formFieldLogin" color='primary'>
            <mat-label>CONTRASEÑA</mat-label>
            <input formControlName="userPass" matInput [type]="passType" placeholder="Contraseña"
                autocomplete="current-password" required (focus)="loginFormFocus('userPass')" class="testInput">
            <button mat-icon-button matSuffix
                matTooltip="{{passType === 'password'? 'Mostrar Contraseña' : 'Ocultar contraseña'}}"
                (click)="showPassword();" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="passType === 'password'">
                <mat-icon>{{passType === 'password' ? 'visibility' : 'visibility_off'}}</mat-icon>
            </button>
            <mat-error *ngIf="loginForm.controls['userPass'].errors?.['required']">
                Debe ingresar una contraseña.
            </mat-error>
            <mat-error *ngIf="loginForm.controls['userPass'].errors?.['minlength']">
                La contraseña debe tener al menos 8 dígitos
            </mat-error>
            <mat-error *ngIf="loginForm.controls['userPass'].errors?.['userPassInvalid']">
                La contraseña es inválida.
            </mat-error>
        </mat-form-field>
        <div class="flex loginOptions">
            <div>
                <a [routerLink]="['/auth/alta-usuario']"><b>Registrarse</b></a>
            </div>
            <div>
                <a [routerLink]="['/auth/recuperar-clave']"><b>Olvidé mi contraseña</b></a>
            </div>
        </div>
        <div class="recaptchaContainer" *ngIf="recaptchaEnabled === true">
            <re-captcha id="captcha" name="captcha" formControlName="captcha" required (resolved)="resolved($event)"
                (error)="onError($event)" errorMode="handled"></re-captcha>
        </div>

        <mat-progress-bar style="margin-bottom: 16px;" class="my1" mode="indeterminate" color='primary' *ngIf="loginIn">
        </mat-progress-bar>

        <button style="width: 100%" mat-raised-button class="btnLogin" #btn type="submit" color="primary"
            (click)='login()' [disabled]='loginForm.invalid'>INICIAR SESIÓN
        </button>
        <div class="ayudaLink">
            <div>¿Necesitás Ayuda? <a (click)='mesaAyuda()'><b>Atención al Usuario</b></a></div>
        </div>
    </form>
</ng-container>