import { Routes } from '@angular/router';
import { LoginComponent } from '../UI/pages/login/login.component';
import { RegisterStepOneComponent } from '../UI/pages/register-step-one/register-step-one.component';
import { RegisterStepTwoComponent } from '../UI/pages/register-step-two/register-step-two.component';
import { RecuperarClaveComponent } from '../UI/pages/recuperar-clave/recuperar-clave.component';
// import { AltaUsuarioComponent } from '../UI/pages/alta-usuario/alta-usuario.component';
// import { RecuperarClaveComponent } from '../UI/pages/recuperar-clave/recuperar-clave.component';
// import { ConfirmarAltaComponent } from '../UI/pages/confirmar-alta/confirmar-alta.component';
// import { LoginExternoComponent } from '../UI/pages/login-externo/login-externo.component';
// import { ConfirmarEmailComponent } from '../UI/pages/confirmar-email/confirmar-email.component';

export const routes: Routes = [
    
  { path: 'auth/iniciar-sesion', component: LoginComponent },
  { path: 'auth/alta-usuario', component: RegisterStepOneComponent },
  { path: 'auth/terminar-alta',component: RegisterStepTwoComponent },
  {path:'auth/recuperar-clave', component:RecuperarClaveComponent}
];
