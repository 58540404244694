import { Observable } from "rxjs";
import { RegisterBody, RegisterStep1Body, RegisterStep2Body } from "../register";

export abstract class AuthRegister{


    abstract register(body:RegisterBody):Observable <boolean>;

    abstract submitStepOne(partialBody:Pick<RegisterBody,"dni"|"sexo"|"nroTramite">):Observable<Partial<RegisterBody>>;

    abstract getPartialBody():Observable<Partial<RegisterBody>>;

}