import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { provideRouter, RouterLink, RouterOutlet } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'
import { LoginComponent } from "../../pages/login/login.component";
import { AuthLoginUsecase } from 'src/app/domain/usecase/Auth/auth-login.usecase';
import { RegisterStepOneComponent } from '../../pages/register-step-one/register-step-one.component';
import { RegisterStepTwoComponent } from '../../pages/register-step-two/register-step-two.component';
import { routes } from 'src/app/config/app.routes';


@Component({
  selector: 'app-auth-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HttpClientModule,
    RouterLink,
    LoginComponent,
    RegisterStepOneComponent,
    RegisterStepTwoComponent,
  ],
  providers:[
    AuthLoginUsecase
  ],
  templateUrl: './auth-layout.component.html',
})
export class AuthLayoutComponent {}
