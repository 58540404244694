import { Component, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, RouterOutlet } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { environment } from 'src/environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { RecaptchaErrorParameters, RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from "ng-recaptcha";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ValidateService } from 'src/app/infraestructure/helpers/validate-format';
import { AuthLoginUsecase } from 'src/app/domain/usecase/Auth/auth-login.usecase';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    RouterModule,
    RecaptchaModule,
    MatSnackBarModule,
    MatTooltipModule,
    RecaptchaFormsModule,
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings,
  },],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  passType = 'password';
  loginIn = false;
  loginError = false;
  loginErrorMensajes = 'Los datos que ingresaste no son correctos. Por favor, verificá la información e intentá nuevamente.';
  recaptchaEnabled = environment.recaptcha.enabled;

  constructor(private formBuilder: FormBuilder, private matSnackbar: MatSnackBar,
    private authLoginService: AuthLoginUsecase, public validateService: ValidateService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      dni: new FormControl('', [Validators.minLength(7), Validators.required]),
      userPass: new FormControl('', [
        Validators.minLength(8),
        Validators.required,
      ]),
      remember: new FormControl('', []),
      captcha: new FormControl('', [Validators.required]),
    })
  }

  loginFormFocus(formControl: string): void {
    this.loginError = false;
  }


  showPassword(): void {
    if (this.passType === 'password') {
      this.passType = 'text';
    } else {
      this.passType = 'password';
    }
  }


  public sendCaptcha(form: FormGroup): void {
  }

  public resolved(captchaResponse: any): void {
    //console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.loginForm.controls['captcha'].setValue(captchaResponse);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    //console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  public mesaAyuda(): void {
    let nextUrl = environment.urlMesaAyuda;
    //console.log(_nextUrl);
    let downloadLink = document.createElement('a');

    downloadLink.setAttribute('target', '_blank');
    downloadLink.href = nextUrl;
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  ngOnDestroy(): void {
    this.loginForm.valueChanges.subscribe()
  }

  login(): void {
    this.loginIn = true;

    localStorage.clear();
    sessionStorage.clear();

    if (this.loginForm.controls['captcha'].value == "") {
      this.matSnackbar.open(
        "reCaptcha no puede estar vacío.",
        undefined,
        {
          duration: 2500,
        }
      );
    }
    const loginBody = { 
      dni: this.loginForm.controls['dni'].value, 
      password: this.loginForm.controls['userPass'].value, 
      recaptcha: this.loginForm.controls['captcha'].value 
    }
    this.authLoginService.login(loginBody).subscribe({
      next: (res) => {
        this.loginIn = false;
      },
      error: (error) => {
        this.loginIn = false;
        this.matSnackbar.open(
          "Login error.",
          undefined,
          {
            duration: 2500,
          }
        );
      }
    })

  }

}
