import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root"
})
export class ValidateService {
  validateFormat(event: Event) {
    let key;
    if (event.type === 'paste') {
      key = (event as ClipboardEvent).clipboardData.getData('text/plain');
    } else {
      key = (event as KeyboardEvent).key;
    }
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      event.returnValue = false;
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  } 
  
}


