import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthLogin } from '../../../domain/models/Auth/login/auth-login';
import { LoginBody, LoginReponse } from '../../../domain/models/Auth/login';
import { RegisterBody, RegisterStep1Body, RegisterStep2Body } from 'src/app/domain/models/Auth/register';
import { AuthRegister } from 'src/app/domain/models/Auth/register/auth-register';
import { REGISTER_URL } from '../../helpers/constants/api_urls';


@Injectable({
  providedIn: 'root'
})
export class AuthRegisterService extends AuthRegister {
    
  private registerBody$=new BehaviorSubject<Partial<RegisterBody>>({});
    
    constructor(private http: HttpClient) { super(); }
    
    override register(body: RegisterBody): Observable<boolean> {
        return this.http.post<boolean>(REGISTER_URL, body);
    }

    override submitStepOne(partialBody:Pick<RegisterBody,"dni"|"sexo"|"nroTramite">){
        this.registerBody$.next(partialBody);
        return of(this.registerBody$.getValue())
    }

    override getPartialBody(): Observable<Partial<RegisterBody>> {
        return of(this.registerBody$.getValue());
    }
    

}
