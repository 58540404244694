import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { AlbumGateway } from '../domain/models/Album/gateway/album-gateway';
import { AlbumApiService } from '../infraestructure/driven-adapter/album-api/album-api.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AuthLogin } from '../domain/models/Auth/login/auth-login';
import { AuthLoginService } from '../infraestructure/driven-adapter/auth-login/auth.login.service';
import { AuthRegister } from '../domain/models/Auth/register/auth-register';
import { AuthRegisterService } from '../infraestructure/driven-adapter/auth-register/auth-register.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withFetch()),
    { provide: AlbumGateway, useClass: AlbumApiService },
    { provide: AuthLogin, useClass: AuthLoginService },
    { provide: AuthRegister, useClass: AuthRegisterService }
  ]
};
