
<div style="width: 100vw;">
  <div class="side_bg">&nbsp;</div>

  <div class="side_outlet">
    <div class="outlet">
      <a [routerLink]="['/auth/iniciar-sesion']">
        <svg>
          <image xlink:href="assets/images/marca-gob-azul-2.svg"  class="marcaGob"/>
        </svg>
      </a>
       <!--  <app-register-step-two></app-register-step-two> -->
         <router-outlet></router-outlet>
    </div>
  </div>
</div>
