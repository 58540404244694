import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterOutlet, RouterModule, Router } from "@angular/router";
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from "ng-recaptcha";
import { RegisterBody } from "src/app/domain/models/Auth/register";
import { AuthLoginUsecase } from "src/app/domain/usecase/Auth/auth-login.usecase";
import { AuthRegisterService } from "src/app/infraestructure/driven-adapter/auth-register/auth-register.service";
import { ValidateService } from "src/app/infraestructure/helpers/validate-format";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-register-step-one",
  templateUrl: "./register-step-one.component.html",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    RouterModule,
    RecaptchaModule,
    MatSnackBarModule,
    MatTooltipModule,
    RecaptchaFormsModule,
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings,
  }, AuthLoginUsecase],
  styleUrl: "./register-step-one.component.scss"
})
export class RegisterStepOneComponent implements OnInit {
  registerForm!: FormGroup;
  registerError = false;
  constructor(private formBuilder: FormBuilder, public validateService: ValidateService, private router: Router, private registerService: AuthRegisterService) {
    this.registerForm = this.formBuilder.group({
      dni: new FormControl('', [Validators.minLength(5), Validators.required]),
      sexo: new FormControl('', [Validators.required]),
      nroTramite: new FormControl('', [Validators.required]),
    })
  }

  ngOnInit(): void {
    this.registerForm.valid
  }

  registerFormFocus(formControl: string): void {
    this.registerError = false;
  }

  register() {
    this.registerService.submitStepOne(this.registerForm.getRawValue()).subscribe({
      next: (_response: Partial<RegisterBody>) => {
        this.router.navigateByUrl('/auth/terminar-alta');
      },
      error: (error: Error) => {
        console.log(error)
      }
    })
  }

}