import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterOutlet, RouterModule, Router } from "@angular/router";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { ValidateService } from "src/app/infraestructure/helpers/validate-format";
import { DialogComponent } from "../../ds/atoms/circular-progress/dialog/dialog.component";
import { AuthRegisterService } from "src/app/infraestructure/driven-adapter/auth-register/auth-register.service";
import { RegisterBody } from "src/app/domain/models/Auth/register";
import { switchMap } from "rxjs";

@Component({
    selector: "app-register-step-two",
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatProgressBarModule,
        RouterModule,
        RecaptchaModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatSnackBarModule,
        RecaptchaFormsModule,
    ],
    templateUrl: "./register-step-two.component.html",
    styleUrl: "./register-step-two.component.scss",
})
export class RegisterStepTwoComponent implements OnInit {
    hide = true;
    hideConfirm = true;
    registerFormStepTwo!: FormGroup;
    registerBody: RegisterBody;
    passwordMatch = false;
    constructor(
        private matSnackbar: MatSnackBar,
        public validateService: ValidateService,
        private dialog: MatDialog,
        private registerService: AuthRegisterService,
        private router: Router
    ) {


        this.registerFormStepTwo = new FormGroup({
            email: new FormControl('', [Validators.minLength(7), Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required]),
            confirmPassword: new FormControl(''),
            confirmacionEmailLegal: new FormControl(false, [Validators.requiredTrue]),
        })

    }
    ngOnInit(): void {
        this.registerService.getPartialBody().subscribe({
            next: (res: Partial<RegisterBody>) => {
                if (!Object.entries(res).length) {
                    this.router.navigateByUrl("auth/alta-usuario");
                }
                this.registerBody = { ...this.registerBody, ...res };
            },
            error: (error) => {
                this.matSnackbar.open("Error, volviendo a alta de usuario", undefined, { duration: 2500 })
                this.router.navigateByUrl("auth/alta-usuario");
            }
        })
        this.registerFormStepTwo.valueChanges.subscribe((res: { password: string, confirmPassword: string }) => { this.passwordMatch = res.password === res.confirmPassword })
    }

    register() {
        this.registerBody = { ...this.registerBody, ...this.registerFormStepTwo.getRawValue() };
        this.registerService.register(this.registerBody).pipe(
            switchMap((res: boolean) => {
                return this.dialog.open(DialogComponent).afterClosed()
            })
        ).subscribe({
            next: (res: unknown) => {
                this.router.navigateByUrl('auth/iniciar-sesion');
            },
            error: (error) => {
                this.matSnackbar.open("Error al registrar", undefined, { duration: 2500 })
            }
        })
        this.dialog.open(DialogComponent).afterClosed().subscribe((res: unknown) => this.router.navigateByUrl('auth/iniciar-sesion'))
    }

    registerFormFocus(data) { }

    ConfirmedValidator(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (
                matchingControl.errors &&
                !matchingControl.errors['confirmedValidator']
            ) {
                return;
            }
            if (!matchingControl.value && !control.value && control.value !== matchingControl.value) {
                matchingControl.setErrors({ confirmedValidator: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }
}