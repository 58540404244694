import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthLogin } from '../../../domain/models/Auth/login/auth-login';
import { LoginBody, LoginReponse } from '../../../domain/models/Auth/login';
import { LOGIN_URL } from '../../helpers/constants/api_urls';


@Injectable({
  providedIn: 'root'
})
export class AuthLoginService extends AuthLogin {
    
    constructor(private http: HttpClient) { super(); }
    
    login(body: LoginBody): Observable<LoginReponse> {
        return this.http.post<LoginReponse>(LOGIN_URL, body);
    }
  
  
}
