import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterOutlet, RouterModule } from '@angular/router';
import { RECAPTCHA_SETTINGS, RecaptchaErrorParameters, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { ValidateService } from 'src/app/infraestructure/helpers/validate-format';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recuperar-clave',
  standalone: true,
  imports: [CommonModule,
    RouterOutlet,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    RouterModule,
    RecaptchaModule,
    MatSnackBarModule,
    MatTooltipModule,
    RecaptchaFormsModule,],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings,
  },],
  templateUrl: './recuperar-clave.component.html',
  styles: ``
})
export class RecuperarClaveComponent implements OnInit {
  loginForm!: FormGroup;
  loginError = false;
  recaptchaEnabled = environment.recaptcha.enabled;

  constructor(private formBuilder: FormBuilder, public validateService: ValidateService
  ) { }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      dni: new FormControl('', [Validators.minLength(6), Validators.required]),
      captcha: new FormControl('', []),
    })
    if(environment.production){
      this.loginForm.get('captcha').addValidators(Validators.required);
    }
  }

  public sendCaptcha(form: FormGroup): void {
  }

  public resolved(captchaResponse: any): void {
    //console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.loginForm.controls['captcha'].setValue(captchaResponse);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    //console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  public mesaAyuda(): void {
    let nextUrl = environment.urlMesaAyuda;
    //console.log(_nextUrl);
    let downloadLink = document.createElement('a');

    downloadLink.setAttribute('target', '_blank');
    downloadLink.href = nextUrl;
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
  register() { }

  loginFormFocus(formControl: string): void {
    this.loginError = false;
  }
}
