<section>
    <svg>
        <image xlink:href="assets/images/marca-gob-azul-2.svg"  class="marcaGob"/>
      </svg>
    <h1>¡Tu solicitud ha sido enviada con exito!</h1>
    <p>¡Gracias por tu solicitud!</p>
    <p>Hemos procesado tu solicitud con exito y hemos enviado un correo electronico a tu direccion de correo electronico
        con un enlace para confirmar el proceso de creacion de tu cuenta. <b>Es importante que verifiques tu correo
            electronico antes de que venza el token.</b></p>
    <p>Si no encuentras el correo electronico en tu bandeja de entrada, te recomendamos verificar la de "carpeta correo
        no deseado" o "spam". A veces, los correos importantes pueden terminar allì por error.</p>
    <p>La confirmación de tu correo electronico es esencial para garantizar que la información de tu cuenta se envíe al
        lugar correcto y que puedas acceder a tu cuenta sin problemas.</p>
    <p>Si tienes alguna pregunta o necesitas asistencia, no dudes en ponerte en contacto con nuestro equipo de soporte.
        Estamos aqui para ayudarte.</p>
    <p>¡Esperamos verte pronto en nuestra plataforma!</p>

    <button (click)="close()" style="width: 100%" mat-raised-button class="btnDone" color="primary">Inicio
    </button>
</section>