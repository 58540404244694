<form [formGroup]="registerFormStepTwo" class="formContainer">
    <mat-form-field appearance="outline" class="formFieldLogin">
        <mat-label>Email</mat-label>
        <input formControlName="email" type="text" matInput placeholder="Email" autocomplete="email" required
            (focus)="registerFormFocus('email')">
        <mat-error *ngIf="registerFormStepTwo.controls['email'].errors?.['required']">
            * Debe ingresar un Email válido.
        </mat-error>
        <mat-error *ngIf="registerFormStepTwo.controls['email'].errors?.['minlength']">
            * Debe ingresar un Email de al menos 7 dígitos.
        </mat-error>
    </mat-form-field>
    <div class="formFieldLogin">
        <mat-checkbox formControlName="confirmacionEmailLegal">Constituyo el correo electronico denunciado como
            domicilio electronico en los terminos de la ley 3002 y Dto. Regl. 1670/18</mat-checkbox>
    </div>
    <mat-form-field appearance="outline" class="formFieldLogin">
        <mat-label>Enter your password</mat-label>
        <input formControlName="password" required matInput [type]="hide ? 'password' : 'text'">
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field appearance="outline" class="formFieldLogin">
        <mat-label>Confirm your password</mat-label>
        <input formControlName="confirmPassword" matInput type="text" [type]="hideConfirm ? 'password' : 'text'">
        <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>
    <div class="button-action">
        <a routerLink="/auth/alta-usuario" style="width: 100%" mat-raised-button class="btnLogin">ANTERIOR
        </a>
        <button style="width: 100%" mat-raised-button class="btnLogin" type="submit" color="primary"
            (click)='register()' [disabled]="registerFormStepTwo.invalid || !passwordMatch">REGISTRARSE
        </button>
    </div>
</form>